import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../components/Layout';
import IntroImage from '../ui/IntroImage';
import Container from '../ui/Container';
import Paragraph from '../ui/Paragraph';
import Title from '../ui/Title';
import List from '../ui/List';
import Footer from '../components/Footer';
import SignUpCTA from '../components/SignUpCTA';
import Nav from '../components/Nav';
import {PRIMARY_COLOR_2} from '../constants';
import EnvData from '../components/EnvData';

export default function RestoraniPage() {
	return (
		<EnvData>
			<ThePage />
		</EnvData>
	);
}

const ThePage = function () {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: {eq: "restoran/vanjska-terasa.jpg"}) {
				childImageSharp {
					# Specify a fixed image and fragment.
					# The default width is 400 pixels
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout
			title="QR Cjenik za restorane"
			description="QR Cjenik je idealni proizvod za restorane koji gostima omogućuje da na jednostavan način vide aktualnu ponudu"
		>
			<Nav background={PRIMARY_COLOR_2} />
			<IntroImage title="QR Cjenik za restorane" image={data.file.childImageSharp.fluid} />
			<Container mt="2rem" width="blog">
				<Paragraph>
					Naša usluga QR cjenika je idealno rješenje za sve vlasnike restorana jer koristi imaju i vlasnici i gosti restorana. Vaši gosti
					dobivaju instant{' '}
				</Paragraph>
				<Title fontSize="1.5rem" mt="2.5rem">
					QR na ulazu u restoran
				</Title>
				<Paragraph>
					Svima je poznato da se ispred mnogih restorana na ulazu nalazi istaknut cjenik, bilo da se radi samo o papiru koju je stavljen na
					ulaz ili je na stoliću ispred ostavljen kompletni cjenik koji nerijetko ima podosta stranica. Gosti koji su u prolazu i još se
					nisu odlučili na Vaš restoran će pogledati cjenik, a u svega par sekundi će odlučiti da li će novac ostaviti kod Vas ili ne. Ako
					vaš cjenik nije privlačan, gost će vrlo vjerojatno produžiti dalje.
				</Paragraph>
				<Paragraph>
					Ako ste svoj cjenik preveli na dva ili više jezika, tada je zagušenost s tekstovima drugih jezika velika, što znači da niti nemate
					mjesta u cjenik staviti slike hrane koje najbolje mogu prodati vaše jelo na prvi pogled. Naša usluga QR cjenika gostu omogućuje da
					vidi sadržaj na onom jeziku koji najviše odgovara gostu, a uz <strong>svaku</strong> stavku možete postaviti sliku koja će
					najbolje gostu prikazati što će dobiti.
				</Paragraph>
				<Paragraph>
					Skeniranjem vašeg QR kȏda, web stranica s ponudom vašeg restorana ostaje na mobilnom uređaju vašeg gosta, što znači da vaš
					potencijalni gost može na miru proučiti ponudu i vratiti se kasnije.
				</Paragraph>
				<Title fontSize="1.5rem" mt="2.5rem">
					Slijedeći korak - gost je došao kod Vas
				</Title>
				<Paragraph>
					Prije nego što su naši klijenti postavili QR kȏd na za svaki stol u svom restoranu su imali klasičnu situaciju da su gosti čekali
					osoblje da netko donese cjenike. QR cjenik uklanja potrebu za čekanjem cjenika, a u današnje COVID vrijeme je odlično što fizički
					opipljiv cjenik neće ići od ruke do ruke.
				</Paragraph>
				<Paragraph>
					Preporučujemo da QR kȏdove zalijepite na svaki stol u svom restoranu ili ih izradite na komadu plastike koji može uspravno stajati
					na stolu. Način na koji ćete postaviti QR kȏd je potpuno na Vama.
				</Paragraph>
				<Paragraph>
					Ako se odlučite za našu uslugu i platite ju za cijelu godinu, možete zatražiti da vam pošaljemo naljepnice za vaše stolove{' '}
					<strong>bez ikakve dodatne naknade</strong>.
				</Paragraph>
				<Title fontSize="1.5rem" mt="2.5rem">
					Imate li dostavu?
				</Title>
				<Paragraph>
					U današnje doba pandemije restorani su primorani dostavljati svoje proizvode kako bi napravili ikakav profit. Bilo da dostavljate
					samostalno ili koristite vanjske dostavne servise, QR cjenik je odlično rješenje jer možete:
				</Paragraph>
				<List>
					<li>navesti sve informacije o načinima dostave, radnom vremenu i sl.</li>
					<li>
						navesti dostavne službe i direktno linkati svoju ponudu na dostavnoj službi; npr. ako dostavljate putem Glova, možete staviti
						link na svoj restoran unutar Glova
					</li>
					<li>u realnom vremenu ažurirati svoj cjenik; ako Vam nešto iz ponude ponestane, stavke možete označiti nedostupnima</li>
				</List>
				<Paragraph>
					Ponekad, restorani uz dostavljenu hranu znaju ostaviti vlastiti promo materijal s trenutno aktualnom ponudom. U tom slučaju,
					postavljanje QR kȏda na promo materijal će omogućiti da vaš potencijalni kupac može dobiti aktualnu informaciju o vašoj ponudi čak
					i mjesecima kasnije.
				</Paragraph>
				<Title fontSize="1.5rem" mt="2.5rem">
					Da li se oglašavate online?
				</Title>
				<Paragraph>
					Da li se oglašavate na Facebooku, Instagramu ili Google-u? Web cjenik koji kreirate kod nas možete koristiti i u marketinškim
					kampanjama, čime ubrzavate proces da vaš potencijalni kupac napravi narudžbu kod Vas. Kada dobijete klik na svoju reklamu,
					preusmjerite potencijalnog kupca na web cjenik koji imate kod nas kako bi kupac odmah dobio kompletnu ponudu vašeg restorana bez
					da mora instalirati ikakvu mobilnu aplikaciju na svom uređaju.
				</Paragraph>
			</Container>
			<SignUpCTA mt="5rem" />
			<Footer />
		</Layout>
	);
};
